<template>
  <div class="s-layout" :style="colorObject">
    <div class="s-container">
      <HeaderV3
        :meta="meta"
        :page-title="moduleConfig.page_title"
        :page-image="moduleConfig.page_image"
        :page-kv-image="moduleConfig.page_kv_image"
        :header-section="moduleConfig.header_section"
      />
      <div class="page">
        <div class="card">
          <template v-if="onEventTime && !isLoading">
            <input
              v-if="editable"
              class="redeem__input"
              placeholder="請輸入序號"
              v-model="redeemCode"
            />
            <div v-else class="redeem">
              <span class="redeem__code">{{ redeemCode }}</span>
            </div>
          </template>
          <div class="card__divider"></div>
          <div class="card__info">
            <template v-if="!hasResult && !isLoading">
              <div v-if="onEventTime" class="card__title">說明</div>
              <div v-html="description"></div>
            </template>
            <template v-if="gift.get_gift && hasResult">
              <div class="text-center">
                <div v-html="AwardText"></div>
                <img class="gift__image" :src="gift.image_url" />
              </div>
            </template>
            <template v-if="!gift.get_gift && hasResult">
              <div class="text-center">
                <div v-html="noAwardText"></div>
              </div>
            </template>
            <template v-if="isLoading">
              <div class="text-center mt-3 mb-3 loading-spinner">
                <b-spinner
                  variant="secondary"
                  style="border-width: 2px;"
                  large
                  />
              </div>
            </template>
          </div>
        </div>

        <div class="btn" v-if="!hasResult && onEventTime">
          <button
            class="s-btn s-btn-bg-primary s-btn-xs"
            type="button"
            @click="redeem()"
            :disabled="isLoading"
          >
            {{ buttonText }}
          </button>
        </div>
        <div class="btn" v-else>
          <button
            class="s-btn s-btn-bg-primary s-btn-xs"
            type="button"
            @click="redirectLine()"
          >
            回 LINE 官方帳號
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import themeColor from "@/mixins/liff/themeColor";
import HeaderV3 from '@/components/Page/Liff/Shared/HeaderV3'
import liff from "@line/liff";
import { isAfter } from "date-fns";

export default {
  mixins: [themeColor],
  components: {
    HeaderV3
  },
  data() {
    return {
      editable: false,
      redeemCode: "",
      themeConfigPage: "redeem",
      hasResult: false,
      resultMessage: "",
      gift: {
        get_gift: false,
      },
      isLoading: false,
      eventCode: null,
      eventInfo: null
    };
  },
  created() {
    if (this.$route.params.redeem_code) {
      this.redeemCode = this.$route.params.redeem_code;
    } else {
      this.editable = true;
    }

    this.eventCode = this.$route.query?.event_code

    let orgCode = this.$route.params.orgCode
    if (orgCode == 'demo-sys' || orgCode == 'pizzahut') {
      this.eventCode = 'pizzahut_genshin_2023_03'
    }

    this.getEventInfo();
  },
  computed: {
    ...mapGetters({
      meta: "liffGeneral/meta",
      themeConfig: "liffGeneral/themeConfig",
      getModule: 'liffModule/getModule',
    }),
    moduleConfig() {
      return this.getModule('liff_redeem').module_config || {}
    },
    description() {
      if (this.onEventTime) {
        return (
          this.themeConfig?.redeem.description ??
          "每則序號只能<b>使用一次</b>，點選『<b>確定前往</b>』後，該序號將無法再次使用。"
        );
      } else {
        return this.eventInfo?.config?.expired_message ?? ''
      }
    },
    buttonText() {
      return this.themeConfig?.redeem.button_text ?? "領取";
    },
    noAwardText() {
      return this.$store.state.liffRedeemCode.liffInfo?.redeem_text.no_award_text
    },
    AwardText() {
      let text = this.$store.state.liffRedeemCode.liffInfo?.redeem_text.award_text

      return text.replace('{{ gift.name }}', this.gift.name)
    },
    onEventTime() {
      if (this.eventCode) {
        if (isAfter(new Date(), new Date(this.eventInfo?.end_at))) {
          return false
        }
      }
      return true
    }
  },
  methods: {
    ...mapActions({
      useRedeemCode: "liffRedeemCode/useRedeemCode",
      getRedeemEvent: "liffRedeemCode/getRedeemEvent",
    }),
    async redeem() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true
      try {
        let params = {
          code: this.redeemCode,
          event: this.eventCode,
        };

        const data = await this.useRedeemCode(params);
        this.gift = data;
        this.hasResult = true;
        this.editable = false;
      } catch (error) {
        this.$swal.fire({
          title: error.message,
          type: "error",
        });
      }
      this.isLoading = false
    },
    redirectLine() {
      const oaLink = this.$store.state.liffRedeemCode.liffInfo?.liff_oa_url
      if (oaLink) {
        window.location.href = oaLink
      } else {
        liff.closeWindow();
      }
    },
    async getEventInfo() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true
      try {
        if (this.eventCode) {
          const data = await this.getRedeemEvent({ code: this.eventCode });
          this.eventInfo = data.event
        }
      } catch (error) {
        this.$swal.fire({
          title: error.message,
          type: "error",
        });
      }
      this.isLoading = false
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/css/liff/main.css";
@import "../../../assets/css/liff/liff_init_theme_config.css";

.s-layout,
.s-layout *,
.s-layout *:before,
.s-layout *:after {
  --s-secondary: #de006f;
  --s-warning: #ed6c00;
  --s-danger: #fe0000;
  --s-gray-darker: #363636;
  --s-gray-dark: #2c2c2e;
  --s-gray: #979797 !important;
  --s-gray-light: #c0c4cc;
  --s-gray-lighter: #f2f2f7;
}

.s-layout {
  color: var(--s-gray-dark);
  background-color: var(--s-gray-lighter);
}

.content {
  height: calc(100vh - 56px);
  overflow-y: auto;
  margin: 0 24px;
}

.logo {
  margin: auto;
  margin-top: 12px;
  margin-bottom: 12px;
  max-width: 300px;
  max-height: 100px;
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.page {
  padding: 16px 12px;
  background: var(--liff-content_bg_color);
}

.card {
  margin-top: 24px;
  background: #ffffff;
  border: 1px solid #e5e5ea;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 24px;
  margin-top: 12px;

  &__title {
    color: #2c2c2e;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    margin-bottom: 8px;
  }

  &__divider {
    border-bottom: 2px dashed #e5e5ea;
  }
  &__info {
    color: #636366;
    line-height: 24px;
    font-size: 14px;
    padding-top: 12px;
    b {
      color: #2c2c2e;
      font-weight: 500;
    }
  }
}

.redeem {
  background: url("./images/bg_redeem.png");
  background-size: cover;
  width: 100%;
  height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
  &__code {
    color: #2c2c2e;
    font-size: 19px;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
  }

  &__input {
    background: #ffffff;
    border: 1px solid #636366;
    border-radius: 10px !important;
    border: 1px solid #636366;
    padding: 15px 12px;
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 32px;

    &::placeholder {
      letter-spacing: -0.408px;
      color: #b7b7b7;
    }
  }
}

.btn {
  margin-top: 24px;
  margin-bottom: 24px;
  width: 100%;

  button {
    background: var(--liff-button-color);
    color: var(--liff-button-text-color);
  }
}

.gift__image {
  width: 100%;
  object-fit: contain;
}
.loading-spinner {
  order: 4;
}
</style>
